<template>
  <main class="page-container" id="sme-apply">
    <common-list :div-class="['page-component']" :is-loading="isLoading" :is-no-result="isNoResult">
      <div>
        <div class="apply-header">
          <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
          <div v-if="isMobile" class="box_select">
            <button type="button" id="select_btn" @click="click">중소벤처기업부</button>
              <ul id="select_list">
                <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
              </ul>
          </div>
          <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
          <!-- <div v-if="isMobile" class="section-notice-arrow">
            <button class="btn-arrow disabled">
              <i class="icon-arrow-prev">이전 페이지</i>
            </button>
            <a href="/support2" class="btn-arrow">
              <i class="icon-arrow-next">다음 페이지</i>
            </a>
          </div> -->
          <h4 class="title">중소벤처기업부</h4>


          <div class="btn_search">
            <input style="height: 50px" v-model="sprtBizNm" type="text" class="form_search" placeholder="사업명을 입력해 주세요" @keyup.enter="getSupportBusinessList"/>
            <button class="ico_search" @click="getSupportBusinessList">
              검색
            </button>
          </div>
        </div>
        <div class="support-title">
          <p class="support-title-desc">
            소상공인의 온라인 유통판로를 지원하는 중소벤처기업부의 사업들을 담은 공간입니다.
          </p>
        </div>
        <div class="apply-list-wrapper">
          <ul class="apply-list">
            <li v-for="(item, idx) in items" class="apply-list-item" :key="idx"  @click="goSupport(item.sprtBizId)">
              <div class="thumbail_img">
                <div v-if="isMobile">
                  <img v-if="item.thmbnlImgPtUrl" :src="item.thmbnlImgPtUrl" alt="" style="height:220px;"/>
                  <img v-else src="../../../assets/img/community/reply_unknown_icon.svg" style="width: 100%; height:220px; object-fit: cover"  alt="">
                </div>
                <div v-else>
                  <img v-if="item.thmbnlImgPtUrl" :src="item.thmbnlImgPtUrl" alt="" />
                  <img v-else src="../../../assets/img/community/reply_unknown_icon.svg" style="width: 100%; height:180px; object-fit: cover"  alt="">
                </div>
              </div>

              <div class="item-category">
                <span v-if="item.instNm !==null">{{item.instNm}}</span>
              </div>
              <div class="apply-items">
                <p class="item-title ellipsis2">{{ item.sprtBizNm }}</p>
                <p class="item-content ellipsis2">{{item.bizDesc}}</p>
                <p class="item-date">{{item.aplyBgngYmd}} ~ {{item.aplyEndYmd}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </common-list>
  </main>
</template>

<script>
import {ACT_GET_SHOP_MALL_COMMON_CODE_LIST, ACT_GET_SUPPORT_BUSINESS_LIST} from '../../../store/_act_consts';
import {getCheckItems, getItems, isSuccess, lengthCheck} from '../../../assets/js/utils';
import { MUT_SET_COMMON_CODE_ITEMS } from "../../../store/_mut_consts";
import CommonList from '../../../components/common/CommonList';
import {mapGetters} from "vuex";
import menus from '../../../assets/js/menus';

export default {
  name: 'Support',
  components: {
    CommonList
  },
  data: () => ({
    isLoading: false,
    bizCtgrDcd: "1021001",
    sortCd: '',
    sprtBizNm: '',
    items: [],
    menus: menus.filter(x => (x.code === 'support')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  computed: {
    ...mapGetters("common", ["isMobile", "commonCode"]),
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    if (this.commonCode.length === 0) this.getCommonCdList();
    this.getSupportBusinessList(true);
  },
  methods: {

    getSupportBusinessList(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`support/${ACT_GET_SUPPORT_BUSINESS_LIST}`, {

        sortCd: this.sortCd,
        sprtBizNm: this.sprtBizNm,
        bizCtgrDcd: this.bizCtgrDcd,
      }).then(res => {
        if(lengthCheck(res)){
          this.items = getItems(res);
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.isLoading = false;
      })
    },
    getCommonCdList() {
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then((res) => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    goSupport(sprtBizId){
      this.$router.push({name:'SupportView', params: {sprtBizId}});
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
};
</script>
